import { LineItem, Money, Quote, SalesItemCode, SalesItemSubType, SalesItemType, Segment } from "../Types";
import { LineItemIsExtraNight } from "../Helpers/LineItemHelper";

export function getOptionalItems(segment: number, quote?: Quote, draft?: Quote): Array<LineItem> {
  if (!quote || !draft) {
    return [];
  }
  if (segment >= quote.segments.length) {
    return [];
  }
  const currentSegment = quote.segments[segment];
  const currentDraftSegment = draft.segments[segment];

  const flightSalesItemCodes = [SalesItemCode.FTEFX, SalesItemCode.FTEF, SalesItemCode.FTLO];

  const optionalItems = currentSegment?.lineItems.filter(
    (lineItem) =>
      lineItem.isOptional &&
      lineItem.type !== SalesItemType.Accommodation &&
      lineItem.type !== SalesItemType.Course &&
      lineItem.subType !== SalesItemSubType.Discount &&
      (lineItem.subType !== SalesItemSubType.Flight || flightSalesItemCodes.includes(lineItem.code as SalesItemCode)) &&
      lineItem.totalPrice.amount > 0
  );
  return currentDraftSegment?.lineItems.filter((lineItem) =>
    optionalItems?.some((optionalItem) => optionalItem.sku === lineItem.sku)
  );
}

function calculateCost(itemSkus: string[], quoteLineItems: LineItem[], optionalItem: boolean): number {
  return quoteLineItems
    .filter((lineItem) => itemSkus.some((optionalItemSku) => optionalItemSku === lineItem.sku))
    .filter((lineItem) => lineItem.isOptional === optionalItem)
    .reduce((sum, lineItem) => sum + lineItem.totalPrice.amount, 0);
}

export function priceDifferenceForOptionalItems(segment: number, quote?: Quote, draft?: Quote): number {
  if (!quote || !draft || segment >= quote.segments.length) {
    return 0;
  }
  const optionalItemSkus = getOptionalItems(segment, quote, draft).map((x) => x.sku);
  const optionalItemsCostDraft = calculateCost(optionalItemSkus, draft.segments[segment].lineItems, false);
  const optionalItemsCostQuote = calculateCost(optionalItemSkus, quote.segments[segment].lineItems, true);

  return optionalItemsCostDraft - optionalItemsCostQuote;
}

export function priceDifferenceForCourseDuration(segment: number, quote?: Quote, draft?: Quote): number {
  if (!quote || !draft || segment >= quote.segments.length) {
    return 0;
  }
  const priceDifferenceOptionalItems = priceDifferenceForOptionalItems(segment, quote, draft);
  const totalPriceDifference =
    draft.segments[segment].price.totalPrice.amount - quote.segments[segment].price.totalPrice.amount;
  return totalPriceDifference - priceDifferenceOptionalItems;
}

export function getAccommodationOptions(segment: Segment): Array<LineItem> {
  return segment.lineItems.filter(
    (lineItem) => lineItem.type === SalesItemType.Accommodation && !LineItemIsExtraNight(lineItem)
  );
}

export function getSelectedAccommodation(segment: Segment): LineItem | undefined {
  return segment.lineItems.find((lineItem) => lineItem.type == SalesItemType.Accommodation && !lineItem.isOptional);
}

export function getPriceDifferenceForChoosingAccommodation(
  segment: Segment,
  accommodationSku: string,
  resultAsTotal: boolean = true
): Money {
  const wishedAccommodationCost = segment.lineItems.find(
    (lineItem) => lineItem.type === SalesItemType.Accommodation && lineItem.sku === accommodationSku
  );

  const selectedAccommodationCost = segment.lineItems.find(
    (lineItem) =>
      lineItem.type === SalesItemType.Accommodation && lineItem.sku === getSelectedAccommodation(segment)?.sku
  );

  const difference = resultAsTotal
    ? (wishedAccommodationCost?.totalPrice?.amount ?? 0) - (selectedAccommodationCost?.totalPrice?.amount ?? 0)
    : (wishedAccommodationCost?.price?.amount ?? 0) - (selectedAccommodationCost?.price?.amount ?? 0);

  return {
    amount: difference,
    currency: (wishedAccommodationCost?.totalPrice?.currency ?? selectedAccommodationCost?.totalPrice?.currency)!,
  };
}

export function getTotalAfterChoosingAccommodation(segment: Segment, accommodationSku: string): Money {
  const changeCost = getPriceDifferenceForChoosingAccommodation(segment, accommodationSku, true);
  const totalCostBeforeChange = segment.price.totalPrice;

  return {
    amount: totalCostBeforeChange.amount + changeCost.amount,
    currency: totalCostBeforeChange.currency,
  };
}
